import {
    DollarSignIcon,
    EllipsisVertical
} from "lucide-react";

import { Button } from "@/components/ui/button";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger
} from "@/components/ui/dropdown-menu";
import Image from "next/image";



export function CardOptionsDropdown({ tournamentId, setFundModalOpen }: { tournamentId: string, setFundModalOpen: (open: boolean) => void }) {
    const handleShare = (platform: 'twitter' | 'discord') => {
        const message = `Think you can outscore me? Challenge me in this tournament on Metacade - https://tournaments.metacade.co/game?tid=${tournamentId}`;
        switch (platform) {
            case 'twitter':
                const twitterUrl = `https://x.com/intent/tweet?text=${encodeURIComponent(message)}`;
                window.open(twitterUrl, '_blank', 'noopener,noreferrer');
                break;
        }
    };

    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <Button variant="secondary" size="icon" className="hover:border-white hover:border-2 rounded-full">
                    <EllipsisVertical className="h-4 w-4" />
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-56">
                <DropdownMenuItem onClick={() => setFundModalOpen(true)}>
                    <DollarSignIcon className="mr-2 h-4 w-4" />
                    <span>Fund</span>
                </DropdownMenuItem>
                <DropdownMenuItem onClick={() => handleShare('twitter')}>
                    <Image className="mr-2 h-4 w-4" src="/x.webp" alt="X" width={20} height={20} />
                    <span>Share on X</span>
                </DropdownMenuItem>
                <DropdownMenuItem onClick={() => window.open(`https://t.me/metacade_tournaments_bot?start=play`, '_blank')}>
                    <Image className="mr-2 h-4 w-4" src="/telegram.png" alt="Telegram" width={16} height={16} />
                    <span>Play on Telegram</span>
                </DropdownMenuItem>
            </DropdownMenuContent>
        </DropdownMenu>
    )
}
