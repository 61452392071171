"use client"

import { ABI } from '@/app/tournament-abi';
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogTrigger } from '@/components/ui/dialog';
import useDebounce from '@/hooks/useDebounce';
import { formatNumber } from '@/lib/utils';
import { useQueryClient } from '@tanstack/react-query';
import { Loader2Icon } from "lucide-react";
import { useEffect, useMemo, useState } from "react";
import { erc20Abi, parseUnits } from "viem";
import { useAccount, useBalance, useChainId, useReadContract, useSimulateContract, useWaitForTransactionReceipt, useWriteContract } from "wagmi";
import { useCapabilities, useWriteContracts } from "wagmi/experimental";
import { CardOptionsDropdown } from './GameCardDropdown';
import { handleInvalidatePrizePool } from './PrizePool';
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { Label } from "./ui/label";
import { useToast } from "./ui/use-toast";

function FundTournamentButton({ tournamentId, paymentTokenName, token, showText, tournamentAddress, paymentTokenAddress, paymentTokenDecimals }: { tournamentId: number, paymentTokenName: string, token: string, showText: boolean, tournamentAddress: `0x${string}`, paymentTokenAddress: string, paymentTokenDecimals: number }) {
    const [fundModalOpen, setFundModalOpen] = useState<boolean>(false);
    const [amount, setAmount] = useState<string>('');
    const { address, connector } = useAccount()
    const balance = useBalance({ address: address, token: paymentTokenAddress as `0x${string}` });
    const debouncedAmount = useDebounce(amount, 500); // 500ms debounce
    const { toast } = useToast();
    const [isLoading, setIsLoading] = useState(false);
    const [needsApproval, setNeedsApproval] = useState(false);
    const chainId = useChainId()
    const queryClient = useQueryClient()

    const { data: availableCapabilities } = useCapabilities({
        account: address,
        query: {
            enabled: Boolean(connector?.name === 'Coinbase Wallet')
        }
    });

    const capabilities = useMemo(() => {
        if (!availableCapabilities || !chainId) return {};
        const capabilitiesForChain = availableCapabilities[chainId];

        if (
            capabilitiesForChain["paymasterService"] &&
            capabilitiesForChain["paymasterService"].supported
        ) {
            return {
                paymasterService: {
                    url: `${process.env.NEXT_PUBLIC_BASE_SITE_URL}/api/paymaster`,
                },
            };
        }
        return {};
    }, [availableCapabilities, chainId]);

    // Define decimals based on the paymentTokenName
    const decimals = paymentTokenDecimals || 18

    const { data: simulateFundTournament } = useSimulateContract({
        address: tournamentAddress,
        abi: ABI,
        functionName: 'fundPrizePool',
        args: [BigInt(tournamentId), parseUnits(amount || '0', decimals)],
    });

    const { writeContract: fundTournament, data: fundTournamentData } = useWriteContract();

    const { writeContracts: writeFundTournamentContracts } = useWriteContracts({
        mutation: {
            onMutate() {
                setIsLoading(true);
            },
            onSuccess: async () => {
                setTimeout(() => {
                    toast({
                        title: "Tournament Funded",
                        description: `You have successfully funded the tournament with ${amount} ${paymentTokenName}.`,
                    });
                    setFundModalOpen(false);
                    setIsLoading(false);
                    handleInvalidatePrizePool(queryClient, tournamentId, chainId, tournamentAddress);
                }, 6000);
            },
            onError() {
                setIsLoading(false);
            },
        }
    });



    const { isSuccess: isFundSuccess, data: waitForFundTransactionHash } = useWaitForTransactionReceipt({
        hash: fundTournamentData,
        confirmations: 2,
        query: {
            enabled: Boolean(fundTournamentData),
        },
    });

    useEffect(() => {
        if (!waitForFundTransactionHash) return;
        toast({
            title: "Tournament Funded",
            description: `You have successfully funded the tournament with ${amount} ${paymentTokenName}.`,
        });
        setFundModalOpen(false);
        setIsLoading(false);
        setTimeout(() => {
            handleInvalidatePrizePool(queryClient, tournamentId, chainId, tournamentAddress);
        }, 4000);
    }, [waitForFundTransactionHash, amount, toast]);

    const { data: allowance, refetch: refetchAllowance } = useReadContract({
        address: paymentTokenAddress as `0x${string}`,
        abi: erc20Abi,
        functionName: "allowance",
        args: [address as `0x${string}`, tournamentAddress],
    });

    const { data: approvalConfig } = useSimulateContract({
        address: paymentTokenAddress as `0x${string}`,
        abi: erc20Abi,
        functionName: "approve",
        args: [tournamentAddress, parseUnits(amount || '0', decimals)],
    });

    const { writeContract: approveWrite, data: approveData } = useWriteContract({
        mutation: {
            onMutate() {
                setIsLoading(true);
            },
            onError() {
                setIsLoading(false);
            }
        }
    });
    const { data: approvalSuccess } = useWaitForTransactionReceipt({
        hash: approveData,
        confirmations: 2,
        query: {
            enabled: Boolean(approveData),
        }
    });

    useEffect(() => {
        if (!approvalSuccess) return;
        refetchAllowance();
        setIsLoading(false);
    }, [approvalSuccess]);

    useEffect(() => {
        const checkAllowance = async () => {
            if (debouncedAmount && address) {
                const parsedAmount = parseUnits(debouncedAmount, decimals);
                const currentAllowance = await refetchAllowance();
                if (!currentAllowance.data) return;
                if (currentAllowance && parsedAmount > currentAllowance.data) {
                    console.log('Setting approval to true');
                    setNeedsApproval(true);
                } else {
                    setNeedsApproval(false);
                }
            }
        };

        checkAllowance();
    }, [debouncedAmount, address, refetchAllowance, allowance]);

    const handleApproval = (amount: string) => {
        if (connector?.name !== 'Coinbase Wallet') {
            if (!approvalConfig) return;
            approveWrite(approvalConfig.request);
        } else {
            const writes = []

            writes.push({
                address: paymentTokenAddress as `0x${string}`,
                abi: erc20Abi,
                functionName: "approve",
                args: [tournamentAddress, parseUnits(amount || '0', decimals)],
            })
            writes.push({
                address: tournamentAddress,
                abi: ABI,
                functionName: "fundPrizePool",
                args: [
                    BigInt(tournamentId),
                    parseUnits(amount || '0', decimals)
                ],
            })
            writeFundTournamentContracts({
                contracts: writes,
                capabilities,
            });
        }
    };

    const handleSubmit = () => {
        if (!allowance || parseUnits(amount || '0', decimals) > allowance) {
            handleApproval(amount);
        } else {
            if (connector?.name !== 'Coinbase Wallet') {
                if (!simulateFundTournament) return;
                fundTournament(simulateFundTournament.request);
            } else {
                const writes = []
                if (needsApproval) {
                    writes.push({
                        address: paymentTokenAddress as `0x${string}`,
                        abi: erc20Abi,
                        functionName: "approve",
                        args: [tournamentAddress, parseUnits(amount || '0', decimals)],
                    })
                }
                writes.push({
                    address: tournamentAddress,
                    abi: ABI,
                    functionName: "fundPrizePool",
                    args: [
                        BigInt(tournamentId),
                        parseUnits(amount || '0', decimals)
                    ],
                })
                setIsLoading(true);
                writeFundTournamentContracts({
                    contracts: writes,
                    capabilities,
                });
            }
        }
    };

    const buttonLabel = useMemo(() => {
        if (isLoading) {
            return <Loader2Icon className='animate-spin w-6 h-6' />;
        } else if (needsApproval) {
            return showText ? "Approve" : null;
        } else {
            return showText ? "Fund" : null;
        }
    }, [isLoading, isFundSuccess, showText, needsApproval, approvalSuccess]);

    return (
        <>
            <Dialog open={fundModalOpen} onOpenChange={setFundModalOpen}>
                <DialogTrigger asChild>
                    <Button
                        variant="secondary"
                        className={`text-white p-1 hover:animate-pulse ${showText ? 'w-full rounded-xl px-4 py-2' : 'w-8 h-8 rounded-full'}`}
                    >
                        {buttonLabel || <CardOptionsDropdown tournamentId={tournamentId.toString()} setFundModalOpen={setFundModalOpen} />}
                    </Button>
                </DialogTrigger>
                <DialogContent className="sm:max-w-[425px]">
                    <DialogHeader>
                        <DialogTitle>Fund Tournament</DialogTitle>
                        <DialogDescription>
                            Enter the amount of {paymentTokenName} you want to contribute to tournament {tournamentId}.
                        </DialogDescription>
                    </DialogHeader>
                    <div className="flex flex-col space-y-4 py-4">
                        <p>Balance: {formatNumber(balance.data?.value.toString() || '0', decimals)} {balance.data?.symbol}</p>

                        <div className="flex items-center">
                            <Label htmlFor="amount" className="w-20">
                                Amount
                            </Label>
                            <Input
                                id="amount"
                                type="number"
                                value={amount}
                                onChange={(e) => setAmount(e.target.value)}
                                className="flex-grow w-40"
                                placeholder={`Enter amount in ${paymentTokenName}`}
                            />
                        </div>
                        <Button
                            onClick={handleSubmit}
                            className="w-full"
                            disabled={isLoading}
                        >
                            {isLoading ? (
                                <Loader2Icon className='animate-spin w-6 h-6 mr-2' />
                            ) : null}
                            {needsApproval && connector?.name === 'Coinbase Wallet' ? 'Fund' : needsApproval ? 'Approve' : 'Fund Tournament'}
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    );
}

export default FundTournamentButton;