import { Card } from "@/components/ui/card";
import { Tournament } from "@/lib/schema";
import { userIdToBase64 } from "@/lib/utils";
import WalletProvider from "@/providers/Wallet";
import { CheckCircleIcon, Clock10, Gamepad, Gamepad2, MedalIcon, UserIcon } from "lucide-react";
import dynamic from "next/dynamic";
import Image from "next/image";
import Link from "next/link";
import { useEffect, useRef, useState } from "react";
import CreditsBox from "./CreditsBox";
import FundTournamentButton from "./FundButton";
import NetworkIcon from "./NetworkIcon";
import TokenIcon from "./TokenIcon";
import { TournamentTooltip } from "./Tooltip";
import { Button } from "./ui/button";

const PrizePool = dynamic(() => import("./PrizePool"), { ssr: false })
const JoinTournamentButton = dynamic(() => import("./JoinTournamentButton"), { ssr: false })

function TournamentGameCard({ paymentTokenSymbol, gameId, address, createdBy, createdByUsername, tournamentId, winnerSpread, gameName, endTime, chainId, status, token, coverAnimationUrl, paymentTokenName, network, coverImageUrl, selected, paymentToken, paymentTokenDecimals }: Tournament & { token: string, selected: boolean, paymentToken: string, paymentTokenDecimals: number }) {
    const [isVideoLoaded, setIsVideoLoaded] = useState(false);
    const [isHovering, setIsHovering] = useState(false);
    const videoRef = useRef<HTMLVideoElement>(null);

    useEffect(() => {
        if (coverAnimationUrl) {
            const video = document.createElement('video');
            video.src = coverAnimationUrl;
            video.preload = "auto";
            video.muted = true;
            video.playsInline = true;
            video.oncanplaythrough = () => setIsVideoLoaded(true);
            video.load();
        }
    }, [coverAnimationUrl]);

    const handleMouseEnter = () => {
        setIsHovering(true);
        if (videoRef.current && isVideoLoaded) {
            videoRef.current.play();
        }
    };

    const handleMouseLeave = () => {
        setIsHovering(false);
        if (videoRef.current) {
            videoRef.current.pause();
            videoRef.current.currentTime = 0;
        }
    };

    const _endTime = new Date(endTime);
    const date = _endTime.toLocaleDateString('en-US');
    const time = _endTime.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
    const hasCompleted = status === 'COMPLETE' || +new Date(endTime) / 1000 < +new Date() / 1000 ? true : false;
    const endsInLessThanOneHour = +new Date(endTime) / 1000 < +new Date() / 1000 + 3600;

    // New: Check if the tournament ends today
    const today = new Date();
    const endsToday = _endTime.toDateString() === today.toDateString();

    // Function to determine the banner color
    const getBannerColor = () => {
        if (hasCompleted) return 'bg-gray-700';
        if (endsInLessThanOneHour) return 'bg-red-700';
        if (endsToday) return 'bg-yellow-600';
        return 'bg-purple-700';
    };

    return (
        <Card className={`col-span-2 p-2 bg-card block overflow-hidden relative transition-shadow duration-300 active-card hover:shadow-lg  ${selected ? 'selected' : ''} cursor-pointer`}>
            <Link prefetch={true} href={`/tournament/${tournamentId}`} scroll={false}>
                <div
                    className="h-52 flex items-center justify-center overflow-hidden relative"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                >
                    {coverImageUrl && (
                        <Image
                            src={coverImageUrl}
                            priority={true}
                            height={384}
                            width={244}
                            className="w-full h-full object-top"
                            alt="Game Logo"
                        />
                    )}
                    {coverAnimationUrl && isVideoLoaded && (
                        <video
                            ref={videoRef}
                            src={coverAnimationUrl}
                            className={`absolute inset-0 w-full h-full object-cover transition-opacity duration-300 ${isHovering ? 'opacity-100' : 'opacity-0'}`}
                            muted
                            playsInline
                            loop
                        />
                    )}
                </div>
            </Link>

            <div className="pt-4 space-y-1">
                <div className="mt-6">
                    <div className="flex items-center space-x-2">
                        <Gamepad2 className="stroke-pink-600" />
                        <span className="text-white font-semibold ">{gameName} - {tournamentId}</span>
                    </div>
                </div>

                <WalletProvider>
                    <PrizePool address={address} tournamentId={tournamentId} winnerSpread={winnerSpread} network={network} chainId={chainId} paymentTokenSymbol={paymentTokenSymbol} paymentTokenDecimals={paymentTokenDecimals} />
                </WalletProvider>
                <TournamentTooltip text="The user that created the tournament">
                    <div className="flex items-center space-x-2">
                        {createdByUsername && createdBy && createdByUsername !== "Metacade" ? (
                            <Link prefetch={false} href={`/profile/${userIdToBase64(createdBy)}`}>
                                <div className="flex items-center space-x-2">
                                    <UserIcon className="stroke-pink-600" />
                                    <p className="text-white font-semibold">{createdByUsername}</p>
                                </div>
                            </Link>
                        ) : (
                            <>
                                <UserIcon className="stroke-pink-600" />
                                <span className="text-white font-semibold flex items-center">Metacade <CheckCircleIcon className="w-3 h-3 text-green-300 ml-1" /></span>
                            </>
                        )}
                    </div>
                </TournamentTooltip>
                <TournamentTooltip text="Practice your skills in free play">
                    <div className="flex items-center space-x-2">
                        <Gamepad className="stroke-pink-600" />
                        <Link prefetch={token ? true : false} className="cursor-pointer" href={`/game/${gameId}`}>
                            <Button variant={'link'} className="text-pink-600 font-semibold text-base hover:animate-pulse p-0 h-0">Free Play</Button>
                        </Link>
                    </div>
                </TournamentTooltip>

                <div className={`marquee ${getBannerColor()} py-2 w-full px-2 shadow-md`}>
                    <div className="marquee-content">
                        <div>
                            <span className="material-icons text-white mr-2">{hasCompleted ? <MedalIcon /> : <Clock10 />}</span>
                            <p className="text-white">
                                {hasCompleted ? 'Ended' : endsToday ? 'Ends Today' : 'Ends'} {endsToday ? time : `${date} ${time}`}
                            </p>
                        </div>
                        <div>
                            <span className="material-icons text-white mr-2">{hasCompleted ? <MedalIcon /> : <Clock10 />}</span>
                            <p className="text-white">
                                {hasCompleted ? 'Ended' : endsToday ? 'Ends Today' : 'Ends'} {endsToday ? time : `${date} ${time}`}
                            </p>
                        </div>
                        <div>
                            <span className="material-icons text-white mr-2">{hasCompleted ? <MedalIcon /> : <Clock10 />}</span>
                            <p className="text-white">
                                {hasCompleted ? 'Ended' : endsToday ? 'Ends Today' : 'Ends'} {endsToday ? time : `${date} ${time}`}
                            </p>
                        </div>
                        <div>
                            <span className="material-icons text-white mr-2">{hasCompleted ? <MedalIcon /> : <Clock10 />}</span>
                            <p className="text-white">
                                {hasCompleted ? 'Ended' : endsToday ? 'Ends Today' : 'Ends'} {endsToday ? time : `${date} ${time}`}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="">
                    <NetworkIcon chainId={chainId} networkName={network} />
                    <TokenIcon tokenImage={paymentTokenName} tokenSymbol={paymentTokenSymbol} />
                </div>
                <WalletProvider>
                    <div className="flex items-center space-x-1">
                        <JoinTournamentButton key={`${tournamentId}_${gameId}`} gameId={gameId} tournamentId={tournamentId} hasCompleted={hasCompleted} token={token} paymentTokenAddress={paymentToken} />
                        <FundTournamentButton tournamentId={tournamentId} paymentTokenName={paymentTokenSymbol} token={token} paymentTokenAddress={paymentToken} paymentTokenDecimals={paymentTokenDecimals} showText={false} tournamentAddress={address as `0x${string}`} />
                        <CreditsBox tournamentId={tournamentId} token={token} />
                    </div>
                </WalletProvider>
            </div>

        </Card>

    )
}

export default TournamentGameCard;
