import { Badge } from "@/components/ui/badge";
import { formatUpperCaseString } from "@/lib/utils";
import Image from "next/image";

function NetworkIcon({ chainId, networkName }: { chainId: number, networkName: string }) {
    return (
        <div className="text-white absolute top-2 left-4 mb-4 cursor-auto">
            <Badge className="space-x-2 text-xs bg-slate-600" variant={'outline'}>
                <Image src={`/${chainId}.png`} alt="Network Icon" width={20} height={20} />
                <p>{formatUpperCaseString(networkName)}</p>
            </Badge>
        </div>

    )
}

export default NetworkIcon